<template>
    <main class="content-wrapper">
        <h1 class="login-logo" @click="backHome">MailPal</h1>
        <div class="login-content">
            <div class="content">
                <h1>Get Notified</h1>
                <p>MailPal is currently still under development, but you can leave your email here and we will notify you once it's ready!</p>
                <div class="form">
                    <input type="email" v-model="email" placeholder="Your email address">
                    <button class="primary-button hero-button" @click="notify">Notify me</button>
                </div>
                <span class="error" v-if="error.length > 0">{{ error }}</span>
                <span class="success" v-if="message.length > 0">{{ message }}</span>
            </div>
        </div>
    </main>
</template>

<script>

    export default {
        name: 'Notify',
        data () {
            return {
                email: "",
                error: "",
                message: ""
            }
        },
        methods: {
            async notify (){
                if (this.email.length < 1) {
                    return this.error = "Please fill out the form above."
                }

                try {
                    const data = {
                        email: this.email
                    }

                    const endpoint = '/api/notify'

                    const result = await fetch(endpoint, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    })

                    if (!result.ok) {
                        return this.error = 'An error ocurred, please try again.'
                    }

                    const json = await result.json()

                    if (json.error !== undefined) {
                        return this.error = json.error || 'An error ocurred, please try again.'
                    }

                    this.error = ''
                    this.message = 'Okay, we will notify once MailPal launches!'

                } catch (err) {
                    console.log(err)
                    this.error = err.message || 'An error ocurred, please try again.'
                }
            },
            backHome(){
                window.location.href = 'https://mailpal.cc'
            }
        },
        created(){
            document.body.parentNode.style.background = "var(--background)";
            document.title = "Get notified - MailPal";
        }
    }
</script>

<style>
    html,
    body {
        color: var(--font);
        background: var(--background);
    }  

    .login-logo{
        position: absolute;
        top: 5px;
        left: 30px;
        cursor: pointer;
    }

    .login-content{
        width: 80%;
        max-width: 500px;
        background: var(--background-sidemenu);
        border-radius: 5px;
        margin: auto;
        padding: 20px 20px;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }

    .content span{
        margin-top: 0.5rem;
        font-size: 17px;
    }

    .error {
        color: var(--red);
    }

    .success {
        color: var(--primary);
    }

    .form {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .form input {
        border: none;
        outline: 0;
        box-shadow: none;
        background: var(--background);
        color: var(--font-light);
        font-size: 17px;
        margin-right: 1rem;
        padding: 10px 20px;
        height: 30px;
        width: 80%;
        max-width: 250px;
        border-radius: 5px;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--font-lighter);
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--font-lighter);
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--font-lighter);
    }

    .hero-button{
        background: var(--primary);
        color: var(--font-dark);
        font-size: 17px;
        height: 50px;
        border-bottom: 5px solid #63C6B1;
    }

</style>